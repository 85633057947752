.profile-update-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preview-profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #ffc107;
    cursor: pointer;
}