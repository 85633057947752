.image-upload-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: flex-start;
    cursor: pointer;
}

.image-upload-default-image {
    width: 100%;
    height: 200px;
}

.btn-upload-template {
    border-radius: 0px 0px 5px 5px!important;
}