/** CSS Overwrite **/
.navbar-nav {
    background: initial;
}

.navbar-brand {
    color: #fff;
    cursor: pointer;
}

.navbar-toggler {
    border-color: #fff;
}

.nav-item {
    margin: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    cursor: pointer;
}

.nav-item > span {
    margin-left: 10px;
    font-size: 14px;
}

.nav-link,
.nav-link:hover,
.dropdown:hover,
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show  {
    background: #2B2A2A!important;
    padding: 0px;
    color: #fff;
}

.dropdown-item {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dropdown-menu,
.dropdown-item.active, .dropdown-item:active {
    background: #2B2A2A!important;
    background-color: #2B2A2A!important;
    color: #fff!important;
}

.dropdown-item:hover {
    background-color: #2e2e2e;
    color: #fff;
}

.dropdown-item > span {
    color: #fff;
}
.dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.dropdown-toggle > span {
    margin-left: 5px;
}
/** Customer CSS **/
body {
    background-color: #1e1d1d;
    color: #fff;
}

.header-logo {
    width: 30px;
    height: 30px;
}

.error-message {
    color: red;
    font-size: small;
}


.card-title {
    padding: 10px;
}

.card-sub-title {
    padding: 10px;
    font-size: small;
    color: #ededed;
}

.login-content {
    width: 100%;
    height: 100vh;
}

.login-form {
    width: 350px;
    background-color: #000000;
    color: #fff;
}

.w-full {
    width: 100%!important;
}

.link-text {
    text-decoration: underline;
    color: #ffc107;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.bg-body-tertiary {
    background-color: rgb(43, 42, 42) !important;
    color: #fff!important;
    box-shadow: 0px 10px 15px -3px rgba(43, 42, 42, 0.1),0px 10px 15px -3px rgba(43, 42, 42, 0.1);
}

.profile-wrapper {
   padding-left: 10px;
   padding-right: 10px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
}


.profile-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.profile-name > span {
    font-size: 14px;
}

.profile-name > span:last-child {
    font-size: small;
    color: #bfbfbf;
}

.alert-wrapper {
    width: 100%;
    padding: 10px;
}

.sidemenu-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left:15px;
    padding-right: 15px;
}

.menu {
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-top: 20px;
}

.menu-item {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
}

.menu-item:hover {
    color:#ffc107;
} 

.menu-item > span {
    padding-left: 10px;
    padding-right: 10px;
}

.balance-card {
    background-color: #caead7;
    color: #000;
} 

.balance-card-text {
    font-weight: bold;
}

.package-card {
    min-height: 350px;
    background-color: rgb(255, 255, 255);
} 

.package-card > .card-title > .card-text {
    font-weight: 800;
}

.package-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.package-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.package-item > span {
    font-size: 14px;
    padding-bottom: 10px;
}

.package-item > span:first-child {
    font-weight: 900;
}

.divider {
    width: 100%;
    height: 2px;
    background-color: #acacac;
    margin-top: 10px;
    margin-bottom: 10px;
}

.package-item-description {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.btn-primary {
    width: 100%;
    background-color: #ffc107;
    color: #000;
    border: 0px;
    font-weight: 900;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    background-color: #ffc107!important;
    color: #000!important;
}

.package-detail-card > .card-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.package-detail-card > .card-title > div > span,
.add-new-bank-account-card > .card-title > .card-text  {
    font-size: 20px;
    font-weight: 600;
}

.card-sub-text {
    font-size: 14px!important;
    font-weight: 300;
}

.image-preview {
    border: 1px solid #e7e1e1;
    border-style: dashed;
    width: 100%;
    height: 300px;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.default-nrc-image {
    width: 100%;
    height: 300px;
    padding: 10px;
}

.investor-title {
    font-weight: 600;
    font-size: 20px;
}

.tr > td {
    font-size: 14px;
}

.btn-small {
    font-weight: 600;
    font-size: small;
}

.thead-tr > th {
    font-size: 14px; 
}

/** Investor Profile Page **/
.investor-profile-card {
    background-color: #fff;
    color: #2e2e2e;
    border-radius: 3px;
}

.investor-profile-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.investor-profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #ffc107;
}

.invetor-profile-info {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-right: 1px solid #d1d1d1;
    flex-wrap: wrap;
}

.invetor-profile-info > span:first-child {
    font-weight: 600;
    font-size: 20px;
    color:#1e1d1d;
    margin-bottom: 20px;
}

.invetor-profile-info > span:first-child > .badge {
    margin-left: 10px;
    font-size: small;
}

.invetor-profile-info > span {
    font-size: 14px;
    color: #353333;
}

.invetor-profile-info > .btn-group {
    margin-top: 20px;
}

.invetor-roi {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.invetor-roi {
    font-size: small;
    color: #000;
    font-weight: 700;
}


/** Notification **/
.notification-wrapper {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0px;
}

.note-description {
    font-weight: 600;
    padding: 10px;
}

.site-title {
    font-weight: 600;
    font-size: 16px;
    padding-left: 15px;
}

.transcation-card-text {
    font-weight: 600;
}

.title {
    font-size: 20px;
}

.alert {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.alert > p {
    margin-left: 10px;
    font-size: 16px;
}

code {
    background-color: #000000;
    color: #fff;
    padding: 3px;
    margin-left: 5px;
    border-radius: 5px;
}

.btn {
    font-weight: 800;
    font-size: 14px;
}

input,select {
    font-size: 14px!important;
}

.card-hover:hover {
    cursor: pointer;
}

.channel-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 600;
}

.channel-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.channel-side > span:last-child {
    font-size: 12px;
    color: #353434;
    padding-right: 10px;
}

.investor-count {
    font-size: 30px;
}

.channel-card-footer {
    background-color: #ffc107;
    color: #000;
    font-weight: 600;
    font-size: 12px;
}

.nav-tabs {
    border: 0px;
}

/* .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #2e2e2e;
    color: #fff;
} */

.form-select,
.form-control,
.form-control:focus,
.form-control[type=file]:not(:disabled):not([readonly]) {
    background-color: #353434;
    color: #fff!important;
    border: 1px solid #656363
}

.form-control::placeholder {
    color: #acacac!important;
}

.nav-tabs > .nav-item {
    margin-left: 5px;
}

.nav-tabs > .nav-item:first-child {
    margin-left: 0px;
}

.modal-header, 
.modal-body,
.modal-footer
 {
    border: 0px;
}

.navbar-toggler,
.collapsed {
    color: #fff!important;
}

.nav-menu-item {
    background-color: #1e1d1d;
}

.deposit-amount-box {
    min-width: 120px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #656363;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    font-size: small;
    margin-top: 10px;
}

.deposit-amount-box > label {
    cursor: pointer;
}

.deposit-amount-box:first-child {
    margin: 0px;
    margin-top: 10px;
}

.active-deposit {
    border-color: #ffc107;
}

.merchant-bank-account-info {
    border: 1px solid #656363;
    border-radius: 5px;
    padding: 20px;
}