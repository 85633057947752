.badge-success {
    color: #fff;
    background-color: #28a745;
}

.badge-primary {
    color: #fff;
    background-color: #007bff;
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}

.badge-dark {
    color: #fff;
    background-color: #343a40;
}